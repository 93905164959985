import {
    ChangeDetectorRef,
    Component,
    Input,
    OnInit,
    ViewChild,
    EventEmitter,
    Output,
    ElementRef,
    ChangeDetectionStrategy,
    OnDestroy,
    SkipSelf,
} from "@angular/core";
import { EventOccUtil } from "../s25.event.occurrence.util";
import { S25ModalComponent } from "../../../../s25-modal/s25.modal.component";
import { S25ReservationUtil } from "../../../models/s25.reservation";
import { S25Util } from "../../../../../util/s25-util";
import { TypeManagerDecorator } from "../../../../../main/type.map.service";
import { S25EventOccurrencesService } from "../../s25.event.occurrences.service";
import { ModalService } from "../../../../modal/modal.service";
import { S25LoadingApi } from "../../../../s25-loading/loading.api";
import { Item } from "../../../../../pojo/Item";
import { EventDataMicroI } from "../../../EventMicroI";
import { EventMicroService } from "../../../../../services/event.micro.service";
import { S25ObjectSearchComponent } from "../../../../s25-object-search/s25.object.search.component";
import { S25Reservation } from "../../../ReservationI";
import { S25EventShareDataService } from "../../s25.event.shared.data.service";
import { Subscription } from "rxjs";
@TypeManagerDecorator("occurrence-action")
@Component({
    selector: "occurrence-action",
    template: ` <div *ngIf="eventState === 98 || eventState === 99">None</div>
        <div *ngIf="eventState !== 98 && eventState !== 99">
            <div class="c-margin-bottom--half">
                <select class="cn-form__control" (change)="onChangeActive($event)">
                    <option value="active" [selected]="occ.state === 'active'">Active</option>
                    <option value="cancelled" [selected]="occ.state === 'cancelled'">Cancelled</option>
                </select>
            </div>
            <div class="buttons">
                <button
                    class="aw-button aw-button--outline"
                    (click)="onActionCopy()"
                    [disabled]="occ.state === 'cancelled'"
                >
                    Copy
                </button>

                <s25-ng-modal #copyOccModal [title]="'Copy Occurrence'" [size]="'xl'">
                    <ng-template #s25ModalBody>
                        <occurrence-additional-details
                            [occ]="copyOcc"
                            [isCopy]="true"
                            [canEdit]="canEdit"
                            [eventId]="eventId"
                            [profileId]="profileId"
                            (onCancel)="cancel()"
                            (onSave)="copyOccModal.close(); refreshF.emit()"
                        >
                        </occurrence-additional-details>
                    </ng-template>
                </s25-ng-modal>
                <button *ngIf="!singleRsrv" class="aw-button aw-button--danger--outline" (click)="onActionDelete()">
                    Delete
                </button>
            </div>

            <div class="spacer">
                <button
                    class="aw-button aw-button--outline c-margin-right--single"
                    (click)="onObjectSearch(4)"
                    [disabled]="occ.state === 'cancelled'"
                >
                    Add Locations
                </button>
                <button
                    class="aw-button aw-button--outline"
                    (click)="onObjectSearch(6)"
                    [disabled]="occ.state === 'cancelled'"
                >
                    Add Resources
                </button>
            </div>

            <s25-loading-inline model="{}" class="c-margin-top--single"></s25-loading-inline>

            <s25-ng-modal
                #objectSearchModal
                [title]="itemTypeId === 4 ? 'Locations Search' : 'Resources Search'"
                [size]="'XL'"
            >
                <ng-template #s25ModalBody>
                    <s25-ng-object-search
                        #objectSearchComponent
                        [itemTypeId]="itemTypeId"
                        [occ]="occ"
                        [profileId]="profileId"
                        [eventId]="eventId"
                        [selectedItems]="accuUpdateItems"
                        (refreshF)="onRefresh($event)"
                    >
                    </s25-ng-object-search>
                </ng-template>
            </s25-ng-modal>
        </div>`,
    styles: [
        `
            :host ::ng-deep .buttons {
                display: flex;
                gap: 0.5em;
                width: fit-content;
                line-height: 1em;
            }

            .spacer {
                margin-top: 0.7em;
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OccurrenceActionComponent implements OnDestroy, OnInit {
    @Input() occ: S25Reservation;
    @Input() eventState: number;
    @Input() eventId?: number;
    @Input() canEdit?: number;
    @Input() profileId?: number;
    @Input() singleRsrv?: boolean; // if single Rsrv no delete button
    @Input() selectedItems?: number[] = [];
    @ViewChild("copyOccModal") copyOccModal: S25ModalComponent;
    @ViewChild("objectSearchModal") objectSearchModal: S25ModalComponent;
    @ViewChild("closeButton") closeButton: any;
    @ViewChild("objectSearchComponent") objSearchComp: S25ObjectSearchComponent;

    @Output() refreshF = new EventEmitter<void>();

    initOcc: S25Reservation;
    copyOcc: S25Reservation;
    itemTypeId: Item.Ids.Location | Item.Ids.Resource;
    accuUpdateItems: number[] = [];

    private selectedItemsSubscription: Subscription;

    constructor(
        private occurrencesService: S25EventOccurrencesService,
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
        @SkipSelf() private shareDataService: S25EventShareDataService,
    ) {}

    ngOnInit() {
        this.initOcc = S25Util.deepCopy(this.occ);
        this.selectedItemsSubscription = this.shareDataService.getSelectedItems().subscribe((items) => {
            this.selectedItems = items;
        });
    }

    async onActionCopy() {
        this.copyOcc = await EventOccUtil.copyOcc(this.occ);
        this.copyOccModal.open();
    }

    async onObjectSearch(id: number) {
        this.accuUpdateItems = Object.values(S25Util.clone(this.selectedItems));
        if (!this.accuUpdateItems.includes(this.occ.itemId)) this.accuUpdateItems.push(this.occ.itemId);
        this.itemTypeId = id;
        this.objectSearchModal.open();
    }

    async onActionDelete() {
        let dialogData = ModalService.dialogType(
            "Yes No",
            {
                message: "Are you sure you want to delete this occurrence?",
                title: "Confirm Deletion",
            },
            "No",
        );
        await ModalService.modal("dialog", dialogData);
        if (dialogData.answer !== 1) return; // User answered no
        this.save(true);
    }

    async onChangeActive(e: any) {
        let payload: EventDataMicroI = {
            items: [
                {
                    kind: "event",
                    id: this.eventId,
                    profiles: [
                        {
                            profileId: this.profileId,
                            reservations: [
                                {
                                    rsrvId: this.occ.itemId, //rsrvId
                                    state: e.target.value === "cancelled" ? 99 : 1,
                                },
                            ],
                        },
                    ],
                },
            ],
        }; //end payload
        if (e.target.value === "cancelled") {
            let dialogData = ModalService.dialogType(
                "Yes No",
                {
                    message: "Are you sure you want to cancelled this occurrence?",
                    title: "Confirm Cancelled",
                },
                "No",
            );

            await ModalService.modal("dialog", dialogData);
            if (dialogData.answer !== 1) return; // User answered no
            S25ReservationUtil.setState(this.occ, e.target.value);
        } else {
            S25ReservationUtil.setState(this.occ, e.target.value);
        }

        this.setLoading(true);
        const [ok, error] = await S25Util.Maybe(EventMicroService.putEventReservation(this.eventId, payload));
        this.setLoading(false);
        if (ok) {
            this.refreshF.emit();
        }
        if (error) return S25Util.showError(error, "There was an error while attempting to update this reservation.");
    }

    cancel() {
        this.occ = this.initOcc;
        this.copyOccModal.close();
    }

    async save(isDeleted: boolean) {
        const event = this.occurrencesService.S25Event;
        const normalizeSaveData = await EventOccUtil.normalizeEventWSData(event, this.occ, false, isDeleted);
        let data = normalizeSaveData[0].data;
        if (isDeleted) {
            delete data.items[0].profiles[0].reservations;
            data.items[0].profiles[0].remove = { reservations: [{ rsrvId: this.occ.itemId }] };
        }

        this.setLoading(true);
        const [ok, error] = await S25Util.Maybe(EventMicroService.microPutEventDetail(data, this.eventId));
        this.setLoading(false);
        if (ok) {
            this.refreshF.emit();
        }
        if (error) return S25Util.showError(error, "There was an error while attempting to update this reservation.");
    }

    onRefresh(e: boolean) {
        //fetch get Event
        if (e) {
            this.refreshF.emit();
        }
        this.objectSearchModal.close();
    }

    setLoading(yes: boolean) {
        if (yes) {
            S25LoadingApi.init(this.elementRef.nativeElement);
        } else {
            S25LoadingApi.destroy(this.elementRef.nativeElement);
        }
        this.cd.detectChanges();
    }

    ngOnDestroy() {
        if (this.selectedItemsSubscription) {
            this.selectedItemsSubscription.unsubscribe();
        }
    }
}
