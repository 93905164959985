import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewEncapsulation,
} from "@angular/core";
import { TypeManagerDecorator } from "../../../../../main/type.map.service";
import { S25Util } from "../../../../../util/s25-util";
import { S25Reservation } from "../../../ReservationI";

@TypeManagerDecorator("occurrence-set-for-all")
@Component({
    selector: "occurrence-set-for-all",
    template: `@if (init) {
        <div>
            <s25-simple-collapse [headerText]="'Set For All'" [defaultCollapsed]="true">
                <div class="collapseContainer c-margin-left--double c-margin-top--single">
                    Please check the occurrences you'd like to apply for the inline edit, add locations and resources.
                    <div class="c-margin-left--single">
                        <s25-ng-checkbox
                            [modelValue]="false"
                            (modelValueChange)="onSelectAll($event)"
                        ></s25-ng-checkbox>
                        Apply All
                    </div>
                    @for (occ of occurrencesList; track occ; let i = $index) {
                        @if (occ.state === "active") {
                            <div class="c-margin-left--single">
                                <s25-ng-shift-selectable-checkbox
                                    [id]="'item-' + i"
                                    [index]="i"
                                    [group]="'occ'"
                                    [modelValue]="selectAll"
                                    (modelValueChange)="onCheckBoxChange(occ, $event)"
                                ></s25-ng-shift-selectable-checkbox>
                                {{ occ.eventStart | dateFormat: dateFormat }}
                            </div>
                        }
                    }
                </div>
            </s25-simple-collapse>
        </div>
    }`,
    styles: [
        `
            .inline-editable-container {
                display: flex;
                align-items: center;
            }
        `,
    ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OccurrenceSetForAllComponent {
    @Input() occurrencesList?: S25Reservation[];
    @Input() dateFormat: string;
    @Input() timeFormat?: string;
    @Output() selectedRsrv = new EventEmitter<number[]>();
    init: boolean;
    selectedItems: number[] = [];
    selectAll: boolean = false;

    constructor(
        private cd: ChangeDetectorRef,
        private elementRef: ElementRef,
    ) {}

    async ngOnInit() {
        this.init = true;
        this.cd.detectChanges();
    }

    onSelectAll(event: boolean) {
        this.selectAll = event;
        if (event) {
            this.selectedItems =
                this.occurrencesList
                    ?.filter((occ: S25Reservation) => occ.state === "active")
                    .map((occ: S25Reservation) => occ.itemId) || [];
        } else {
            this.selectedItems = [];
        }
        this.selectedRsrv.emit(this.selectedItems);
        this.cd.detectChanges();
    }

    onCheckBoxChange(occ: S25Reservation, event: boolean) {
        if (event && !this.selectedItems.includes(occ.itemId)) {
            this.selectedItems.push(occ.itemId);
        } else {
            const index = this.selectedItems.indexOf(occ.itemId);
            if (index !== -1) this.selectedItems.splice(index, 1);
        }
        this.selectedRsrv.emit(this.selectedItems);
        this.cd.detectChanges();
    }
}
