import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
} from "@angular/core";
import { Table } from "../../../../s25-table/Table";
import { TypeManagerDecorator } from "../../../../../main/type.map.service";
import { S25Util } from "../../../../../util/s25-util";
import { UserprefService } from "../../../../../services/userpref.service";
import { S25Datefilter } from "../../../../s25-dateformat/s25.datefilter.service";
import { S25EventOccurrencesService } from "../../s25.event.occurrences.service";
import { S25ProfileUtil } from "../../../models/s25.profile";
import { S25LoadingApi } from "../../../../s25-loading/loading.api";
import { Etags, EventService } from "../../../../../services/event.service";
import { S25Reservation } from "../../../ReservationI";
import { S25Event } from "../../../EventMicroI";
import { S25Profile, AdditionalTime, Ids } from "../../../ProfileI";
import { EventMicroService } from "../../../../../services/event.micro.service";
import { S25ReservationUtil } from "../../../models/s25.reservation";
import { SpaceService } from "../../../../../services/space.service";

@TypeManagerDecorator("occurrence-added-time")
@Component({
    selector: "occurrence-added-time",
    template: ` @if (init) {
        <div>
            <s25-simple-collapse [headerText]="'Additional Time'" [defaultCollapsed]="true">
                <div class="collapseContainer c-margin-left--double c-margin-top--single">
                    @for (a of additionalTime; track a; let i = $index) {
                        <div class="c-margin-bottom--single">
                            <div>{{ a.label }}</div>
                            <div class="inline-editable-container">
                                <s25-ng-editable-number
                                    [(val)]="a.days"
                                    [readOnly]="readOnly"
                                    [min]="0"
                                    [max]="999"
                                    (change)="onChange(i, a.id)"
                                >
                                </s25-ng-editable-number>
                                <span class="c-margin-right--double">Days </span>
                                <s25-ng-editable-number
                                    [(val)]="a.hours"
                                    [readOnly]="readOnly"
                                    [min]="0"
                                    [max]="23"
                                    (change)="onChange(i, a.id)"
                                >
                                </s25-ng-editable-number>
                                <span class="c-margin-right--double">Hours </span>
                                <s25-ng-editable-number
                                    [(val)]="a.minutes"
                                    [readOnly]="readOnly"
                                    [min]="0"
                                    [max]="59"
                                    (change)="onChange(i, a.id)"
                                >
                                </s25-ng-editable-number>
                                <span class="c-margin-left--double c-margin-right--double">Minutes </span>
                            </div>
                        </div>
                    }
                    <div class="c-margin-top--half">
                        Reservation Start: <span class="ngBold c-displayBlock">{{ reservationStartDt }}</span>
                    </div>
                    <div class="c-margin-top--half c-margin-bottom--half">
                        Reservation End: <span class="ngBold c-displayBlock">{{ reservationEndDt }}</span>
                    </div>
                    <div class="rose-form--additional-time--summary c-text-align--center c-margin-bottom--half">
                        Reservation Duration:
                        <span class="c-displayBlock ngBold">{{ reservationDuration }}</span>
                    </div>
                    <div class=" c-margin-top--single c-margin-bottom--half">
                        <button class="aw-button aw-button--primary c-margin-right--single" (click)="save()">
                            Save
                        </button>
                        <button class="aw-button aw-button--outline c-margin-right--single" (click)="cancel()">
                            Cancel
                        </button>
                    </div>
                    <s25-loading-inline model="{}" class="c-margin-top--single"></s25-loading-inline>
                </div>

                @if (findLocationConflict.length > 0 || findResourcesConflict.length > 0) {
                    <div class="c-margin-left--single ngRed">Conflicts:</div>
                }

                @if (findLocationConflict.length > 0) {
                    @for (loc of findLocationConflict; track loc.space_id; let i = $index) {
                        <div class="c-margin-left--single">{{ loc.space_name }}</div>
                        @for (date of loc.dates; track date) {
                            <div class="c-margin-left--double c-margin-bottom--double">
                                {{ date.start_dt | dateFormat: dateTimeformat }}
                            </div>
                        }
                    }
                }

                @if (findResourcesConflict.length > 0) {
                    @for (res of findResourcesConflict; track res.space_id; let i = $index) {
                        <div class="c-margin-left--single">{{ res.resource_name }}</div>
                        @for (date of res.dates; track date) {
                            <div class="c-margin-left--double c-margin-bottom--double">
                                {{ date.start_dt | dateFormat: dateTimeformat }}
                            </div>
                        }
                    }
                }
            </s25-simple-collapse>
        </div>
    }`,
    styles: [
        `
            .inline-editable-container {
                display: flex;
                align-items: center;
            }

            .inline-editable-container > s25-ng-editable-number {
                margin-right: 10px;
            }

            .inline-editable-container > s25-ng-editable-number:last-of-type {
                margin-right: 0;
            }

            :host ::ng-deep .c-svgIcon:hover {
                cursor: pointer;
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OccurrenceAddedTimeComponent {
    @Input() occ?: S25Reservation;
    @Input() readOnly?: boolean;
    @Input() profileId?: number;

    @Output() toggleDetailsRow: EventEmitter<Table.NewRowModel> = new EventEmitter<Table.NewRowModel>();

    initOcc: S25Reservation;
    showAddedTime: boolean = false;
    init: boolean = false;
    additionalTime: AdditionalTime[] = [
        { id: "setupStart", label: "Setup Time", days: 0, hours: 0, minutes: 0 },
        { id: "preEventStart", label: "Pre-Event Time", days: 0, hours: 0, minutes: 0 },
        { id: "postEventEnd", label: "Post-Event Time", days: 0, hours: 0, minutes: 0 },
        { id: "takeDownEnd", label: "Takedown Time", days: 0, hours: 0, minutes: 0 },
    ];

    reservationDuration: string;
    reservationStartDt: string;
    reservationEndDt: string;
    event: S25Event;
    initRsrv: S25Reservation[]; // all occs
    profile: S25Profile;
    findResourcesConflict: [] = [];
    findLocationConflict: [] = [];
    hasConflict: boolean = true;

    dateTimeformat: string;

    constructor(
        private cd: ChangeDetectorRef,
        private elementRef: ElementRef,
        private occurrencesService: S25EventOccurrencesService,
    ) {}

    async ngOnInit() {
        this.event = await this.occurrencesService.S25Event;
        this.profile = S25ProfileUtil.getProfileByProfileId(this.event.profile, this.profileId);
        this.initRsrv = S25Util.clone(this.profile.reservations);
        this.setAdditionalTime();
        this.setReservationDurations();
        this.init = true;
        this.cd.detectChanges();
    }

    onChange(index: number, id: Ids) {
        this.updateOccDates();
        this.cd.detectChanges();
    }

    setAdditionalTime() {
        const addedTime = this.profile.occurrenceDefn?.addedTime;
        if (addedTime && addedTime?.preEvent) this.updateAdditionalTime("preEventStart", addedTime?.preEvent);
        if (addedTime && addedTime?.postEvent) this.updateAdditionalTime("postEventEnd", addedTime?.postEvent);
        if (addedTime && addedTime?.setup) this.updateAdditionalTime("setupStart", addedTime?.setup);
        if (addedTime && addedTime?.takedown) this.updateAdditionalTime("takeDownEnd", addedTime?.takedown);
    }

    getDurationString(startDt: Date, endDt: Date) {
        let minuteDur = S25Util.date.diffMinutes(startDt, endDt);
        return S25Util.getDurationString(minuteDur);
    }

    async setReservationDurations() {
        let rsrv: S25Reservation = this.profile.reservations[0] as S25Reservation;
        this.dateTimeformat = await UserprefService.getS25DateTimeformat();

        const preEventStart = this.additionalTime.find((item) => item.id === "preEventStart");
        const setupStart = this.additionalTime.find((item) => item.id === "setupStart");
        const preEventStartMillis = this.timeToMilliseconds(
            preEventStart.days,
            preEventStart.hours,
            preEventStart.minutes,
        );
        const setupStartMillis = this.timeToMilliseconds(setupStart.days, setupStart.hours, setupStart.minutes);
        const rsrvStartDate = new Date(rsrv.eventStart.getTime() - preEventStartMillis - setupStartMillis);
        this.reservationStartDt = S25Datefilter.transform(rsrvStartDate, this.dateTimeformat);

        const postEventEnd = this.additionalTime.find((item) => item.id === "postEventEnd");
        const takeDownEnd = this.additionalTime.find((item) => item.id === "takeDownEnd");
        const postEventEndMillis = this.timeToMilliseconds(postEventEnd.days, postEventEnd.hours, postEventEnd.minutes);
        const takeDownEndMillis = this.timeToMilliseconds(takeDownEnd.days, takeDownEnd.hours, takeDownEnd.minutes);
        const rsrvEndDate = new Date(rsrv.eventEnd.getTime() + postEventEndMillis + takeDownEndMillis);
        this.reservationEndDt = S25Datefilter.transform(rsrvEndDate, this.dateTimeformat);

        this.reservationDuration = this.getDurationString(rsrvStartDate, rsrvEndDate);

        this.cd.detectChanges();
    }

    updateAdditionalTime(id: Ids, duration: string) {
        if (duration) {
            let getDHM = this.parseDuration(duration);
            let timeItem = this.additionalTime.find((item) => item.id === id);
            if (timeItem) {
                timeItem.days = getDHM.days;
                timeItem.hours = getDHM.hours;
                timeItem.minutes = getDHM.minutes;
            }
            return timeItem;
        }
    }

    updateOccDates() {
        const addTime = (date: Date, days: number, hours: number, minutes: number) => {
            let newDate = new Date(
                date.getTime() + days * 24 * 60 * 60 * 1000 + hours * 60 * 60 * 1000 + minutes * 60 * 1000,
            );
            return new Date(newDate);
        };

        this.additionalTime.forEach((time: any) => {
            switch (time.id) {
                case "setupStart":
                    this.profile.reservations.forEach((rsrv: any) => {
                        rsrv.setupStart = addTime(rsrv.eventStart, -time.days, -time.hours, -time.minutes);
                        if (rsrv.preEventStart)
                            rsrv.setupStart = addTime(
                                rsrv.setupStart,
                                -this.additionalTime[1].days,
                                -this.additionalTime[1].hours,
                                -this.additionalTime[1].minutes,
                            ); // subtract  pre event times
                    });
                    break;
                case "preEventStart":
                    this.profile.reservations.forEach((rsrv: any) => {
                        rsrv.preEventStart = addTime(rsrv.eventStart, -time.days, -time.hours, -time.minutes);
                        if (rsrv.setupStart)
                            rsrv.setupStart = addTime(
                                rsrv.preEventStart,
                                -this.additionalTime[0].days,
                                -this.additionalTime[0].hours,
                                -this.additionalTime[0].minutes,
                            ); // also update set up times
                    });
                    break;
                case "postEventEnd":
                    this.profile.reservations.forEach((rsrv: any) => {
                        rsrv.postEventEnd = addTime(rsrv.eventEnd, time.days, time.hours, time.minutes);
                        if (rsrv.takeDownEnd)
                            rsrv.takeDownEnd = addTime(
                                rsrv.postEventEnd,
                                this.additionalTime[3].days,
                                this.additionalTime[3].hours,
                                this.additionalTime[3].minutes,
                            ); // update take Down times
                    });
                    break;
                case "takeDownEnd":
                    this.profile.reservations.forEach((rsrv: any) => {
                        rsrv.takeDownEnd = addTime(rsrv.eventEnd, time.days, time.hours, time.minutes);
                        if (rsrv.postEventEnd)
                            rsrv.takeDownEnd = addTime(
                                rsrv.takeDownEnd,
                                this.additionalTime[2].days,
                                this.additionalTime[2].hours,
                                this.additionalTime[2].minutes,
                            ); // add post event time
                    });
                    break;
                default:
                    break;
            }
        });
        this.setReservationDurations();
        this.cd.detectChanges();
    }

    cancel() {
        this.occ = this.initOcc;
        this.profile.reservations = this.initRsrv;
        this.setAdditionalTime();
        this.setReservationDurations();
        this.cd.detectChanges();
    }

    async save() {
        const event: S25Event = await this.occurrencesService.S25Event;
        const addedTime = S25ProfileUtil.normalizeAddedTime(this.additionalTime);
        if (Object.keys(addedTime).length === 0) {
            this.profile.occurrenceDefn.addedTime = {
                setup: "",
                preEvent: "",
                postEvent: "",
                takedown: "",
            };
        } else {
            this.profile.occurrenceDefn.addedTime = addedTime;
        }

        event.profile = [this.profile];
        let normalizeData = await S25ProfileUtil.normalizeProfileWSData(event, event.profile);
        let data = normalizeData[0].data;

        //check objects conflict before save
        await this.checkObjectsAvailability(event.itemId);
        if (this.hasConflict) {
            this.cd.detectChanges();
            return false;
        }

        this.setLoading(true);
        const [ok, error] = await S25Util.Maybe(EventMicroService.microPutEventDetail(data, event.itemId));
        this.setLoading(false);
        if (error) return S25Util.showError(error, "There was an error while attempting to update this profile.");
    }

    parseDuration(duration: string): { days: number; hours: number; minutes: number } {
        // Define regex patterns for days, hours, and minutes
        const dayPattern = /P(\d+)D/;
        const hourPattern = /T(\d+)H/;
        const minutePattern = /T(\d+)M/;

        // Extract values using regex exec
        const dayMatch = duration.match(dayPattern);
        const hourMatch = duration.match(hourPattern);
        const minuteMatch = duration.match(minutePattern);

        // Convert extracted values to numbers or default to 0 if not found
        const days = dayMatch ? parseInt(dayMatch[1], 10) : 0;
        const hours = hourMatch ? parseInt(hourMatch[1], 10) : 0;
        const minutes = minuteMatch ? parseInt(minuteMatch[1], 10) : 0;

        // Return the results
        return { days: days, hours: hours, minutes: minutes };
    }

    setLoading(yes: boolean) {
        if (yes) {
            S25LoadingApi.init(this.elementRef.nativeElement);
        } else {
            S25LoadingApi.destroy(this.elementRef.nativeElement);
        }
        this.cd.detectChanges();
    }

    timeToMilliseconds(days: number, hours: number, minutes: number) {
        return days * 24 * 60 * 60 * 1000 + hours * 60 * 60 * 1000 + minutes * 60 * 1000;
    }

    async checkObjectsAvailability(eventId: number) {
        let promiseArr = [];
        let dates = await S25ReservationUtil.getReservationsStartEndDates(
            this.profile.reservations as S25Reservation[],
        );

        let locations = this.event.expandedInfo.spaces.map((item) => item.spaceId);
        // possible occurrences requested resources quality differences, so check each occ
        for (const rsrv of this.profile.reservations) {
            promiseArr.push(
                S25ReservationUtil.checkObjectsDatesAvailability(
                    eventId,
                    this.profileId,
                    dates as [],
                    [],
                    rsrv.resources,
                ),
            );
        }

        if (locations.length > 0)
            promiseArr.push(SpaceService.getSpaceDatesAvailability(locations, dates, eventId, this.profileId));
        if (promiseArr) {
            return S25Util.all(promiseArr).then((data) => {
                data.forEach((item: any) => {
                    // Check if the object has properties named "resources" and "spaces"
                    if ("resources" in item) {
                        this.findResourcesConflict = item.resources.resource.filter(function (i: any) {
                            return i.has_conflicts === "T";
                        });
                    }
                    if ("spaces" in item) {
                        this.findLocationConflict = item.spaces.space.filter(function (i: any) {
                            return i.has_conflicts === "T";
                        });
                    }
                });

                if (this.findLocationConflict.length > 0 || this.findResourcesConflict.length > 0) {
                    alert("Conflict, unable to save the changes!");
                } else {
                    this.hasConflict = false;
                }
            });
        }
    }
}
