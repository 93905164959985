import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
} from "@angular/core";
import { Table } from "../../../../s25-table/Table";
import { Bind } from "../../../../../decorators/bind.decorator";

import { TypeManagerDecorator } from "../../../../../main/type.map.service";
import { S25Reservation } from "../../../ReservationI";
@TypeManagerDecorator("occurrence-segment")
@Component({
    selector: "occurrence-segment",
    template: ` @if (init) {
        <div>
            @if (eventState && (eventState === 98 || eventState === 99)) {
                <div>{{ segmentName }}</div>
            }
            @if ((eventState && eventState !== 98 && eventState !== 99) || occ.state !== "cancelled") {
                <div (click)="showDetails($event)" class="occurrence-date-wrapper">
                    @if (!toggleShowDetails) {
                        <svg class="c-svgIcon caret-right" role="img">
                            <title>Previous page</title>
                            <use
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#caret--caret-right"
                            ></use>
                        </svg>
                    }
                    @if (toggleShowDetails) {
                        <svg class="c-svgIcon caret-down" role="img">
                            <title>Previous page</title>
                            <use
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#caret--caret-down"
                            ></use>
                        </svg>
                    }
                    <div>{{ segmentName }}</div>
                </div>
            }
        </div>
    }`,
    styles: [
        `
            .occurrence-date-wrapper {
                display: flex;
                gap: 0.5em;
            }

            :host ::ng-deep .c-svgIcon:hover {
                cursor: pointer;
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OccurrenceSegmentComponent {
    @Input() segmentName: string;
    @Input() occ: S25Reservation;
    @Input() eventState: number;

    @Output() toggleDetailsRow: EventEmitter<Table.NewRowModel> = new EventEmitter<Table.NewRowModel>();

    toggleShowDetails: boolean = false;
    init: boolean = false;

    constructor(
        private cd: ChangeDetectorRef,
        private elementRef: ElementRef,
    ) {}

    ngOnInit() {
        this.init = true;
    }

    @Bind
    showDetails(event: MouseEvent) {
        const caretEl = this.elementRef.nativeElement.querySelector(
            `.${this.toggleShowDetails ? "caret-down" : "caret-right"}`,
        );
        const table = caretEl.parentElement.offsetParent.offsetParent;
        const row = caretEl.parentElement.offsetParent.parentElement;

        const tableData: Table.NewRowModel = {
            table: table,
            row: row,
            rowIndex: Array.from(table.children[2].children).indexOf(row),
            action: this.toggleShowDetails ? "delete" : "create",
            data: { occ: this.occ, isCopy: false },
        };
        this.toggleShowDetails = !this.toggleShowDetails;
        this.toggleDetailsRow.emit(tableData);
        this.cd.detectChanges();
    }
}
