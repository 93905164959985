import { Component, OnInit, Input, ChangeDetectorRef, Output, EventEmitter } from "@angular/core";
import { Space, Resource } from "../EventMicroI";

import { TypeManagerDecorator } from "../../../main/type.map.service";

@TypeManagerDecorator("s25-ng-ev-colander")
@Component({
    selector: "s25-ng-ev-colander",
    template: `
        @if (init) {
            <div class="spacer-bottom">
                <div class="evdo-panel">
                    <span
                        class="{{
                            type === 'location'
                                ? 's25-item-type-icon-holder-spaces'
                                : 's25-item-type-icon-holder-resources'
                        }}"
                    >
                        <span
                            class="{{ type === 'location' ? 'icon-space' : 'icon-resource' }} s25item-type-icon"
                        ></span>
                    </span>
                    <span class="ngBold">Filter By {{ type === "location" ? "Locations" : "Resources" }}</span>
                    @if (!isExpand) {
                        <span class="floatRight" (click)="expandToggle(true)">
                            <svg class="c-svgIcon" role="button">
                                <title>Expand</title>
                                <use
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#caret--caret-up"
                                ></use>
                            </svg>
                        </span>
                    }
                    @if (isExpand) {
                        <span class="floatRight" (click)="expandToggle(false)">
                            <svg class="c-svgIcon" role="button">
                                <title>Collape</title>
                                <use
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#caret--caret-down"
                                ></use>
                            </svg>
                        </span>
                    }
                </div>
                @if (isExpand) {
                    <div class="evdo-panel">
                        <span
                            ><input class="s25-checkmark" type="checkbox" checked (change)="selectAll($event)" /> Select
                            All
                        </span>
                        <span class="floatRight">
                            <input
                                type="radio"
                                id="any"
                                name="{{ type }}"
                                value="any"
                                checked
                                (click)="filter($event)"
                            />
                            Any
                            <input type="radio" id="all" name="{{ type }}" value="all" (click)="filter($event)" /> All
                        </span>
                    </div>
                }
                @if (isExpand) {
                    <div class="evdo-panel">
                        @for (d of data; track d) {
                            <div>
                                <input
                                    class="s25-checkmark"
                                    type="checkbox"
                                    [checked]="isSelectedAll"
                                    [name]="type === 'location' ? d?.spaceName : d?.resourceName"
                                    (change)="onCheckBox($event, type === 'location' ? d.spaceId : d.resourceId)"
                                />
                                {{ type === "location" ? d?.spaceName : d?.resourceName }}
                            </div>
                        }
                    </div>
                }
            </div>
        }
    `,
    styles: [
        `
            ::ng-deep .floatRight {
                float: right !important;
            }

            ::ng-deep .evdo-panel {
                border: 1px solid #999;
                background-color: #fff;
                font-size: 1rem !important;
                padding: 10px;
            }

            ::ng-deep input[type="checkbox"] {
                border: 1px solid rgba(0, 0, 0, 0.25);
                border-radius: 3px;
                background-color: #fafaf9;
            }

            .spacer-bottom {
                margin-bottom: 20px;
            }
        `,
    ],
})
export class S25EventColanderComponent implements OnInit {
    @Input() data?: Space[] | Resource[];
    @Input() type: string = "location" || "resource";
    @Output() modelValueChange = new EventEmitter<{ filterType: string; itemList: number[] }>();

    isExpand: boolean = true;
    init: boolean = false;
    itemList: number[];
    isSelectedAll: boolean = true;
    filterType: string = "any";

    constructor(private cd: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.type === "location"
            ? (this.itemList = this.data.map((item: any) => item.spaceId))
            : (this.itemList = this.data.map((item: any) => item.resourceId));
        this.init = true;
    }

    expandToggle(e: boolean) {
        this.isExpand = e;
        this.cd.detectChanges();
    }

    selectAll(e: any) {
        this.isSelectedAll = e.target.checked;
        if (e.target.checked) {
            this.type === "location"
                ? (this.itemList = this.data.map((item: any) => item.spaceId))
                : (this.itemList = this.data.map((item: any) => item.resourceId));
        } else {
            this.itemList = [];
        }

        // if no itemList selected,  nothing till at lease one box checked
        if (this.itemList.length > 0)
            this.modelValueChange.emit({ itemList: this.itemList, filterType: this.filterType });
        this.cd.detectChanges();
    }

    filter(e: any) {
        this.filterType = e.target.value;
        this.modelValueChange.emit({ itemList: this.itemList, filterType: this.filterType });
    }

    onCheckBox(e: any, id?: number) {
        if (e.target.checked) {
            let find = this.itemList.filter((i: number) => {
                return i === id;
            });
            find.length === 0 ? this.itemList.push(id) : "";
        } else {
            this.itemList = this.itemList.filter((i: number) => {
                return i !== id;
            });
        }
        if (this.itemList.length > 0)
            this.modelValueChange.emit({ itemList: this.itemList, filterType: this.filterType });
    }
}
