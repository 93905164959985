import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    Input,
    EventEmitter,
    Output,
    ChangeDetectorRef,
    ElementRef,
    ViewChild,
} from "@angular/core";

/**
 * Displays gear icon, lets the user select between 'seperated' and 'merged view'. migration of evdo-menu
 * OTHER NOTES:
 * Commented out "Delete This Segment"  event micro service current not support this feature
 * ACCL-1752 getting very large, don't want adding any new functionality
 *
 */
@Component({
    selector: "s25-ng-occ-menu",
    template: ` <div class="occ-menu" (click)="onViewChange()">
            {{ isSeparatedView ? "Merged View" : "Separated View" }}
        </div>
        @if (this.canEdit) {
            <div class="occ-menu" (click)="addSegment()">Add a New Segment</div>
        }
        @if (this.canEdit && this.isSeparatedView) {
            <div class="occ-menu" (click)="copySegment()">Copy This Segment</div>
            <!--  <div class="occ-menu" (click)="deleteSegment()">Delete This Segment</div> -->
        }`,
    styles: [
        `
            .occ-menu {
                padding: 15px;
                font-size: 16px !important;
                font-weight: 600;
                color: #555;
                cursor: pointer;
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EvOccMenuComponent {
    @Input() isSeparatedView: boolean;
    @Input() profileId?: number;
    @Input() canEdit?: boolean = false;
    @Output() modelValueChange: EventEmitter<EvOccMenuOutput> = new EventEmitter<EvOccMenuOutput>();

    init: boolean = false;

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {}

    // ngOnInit(): void {
    //     this.init = true;
    //     this.cd.detectChanges();
    // }

    copySegment() {
        this.isSeparatedView = true;
        this.modelValueChange.emit({
            isSeparatedView: this.isSeparatedView,
            addNew: true,
            data: this.profileId,
        });
    }

    //go to seperated view and indicate that a new segment is added
    addSegment() {
        this.isSeparatedView = true;
        this.modelValueChange.emit({ isSeparatedView: this.isSeparatedView, addNew: true });
    }

    onViewChange() {
        this.isSeparatedView = !this.isSeparatedView;
        this.modelValueChange.emit({ isSeparatedView: this.isSeparatedView, addNew: false });
    }

    deleteSegment() {
        this.isSeparatedView = true;
        this.modelValueChange.emit({
            isSeparatedView: this.isSeparatedView,
            addNew: false,
            data: this.profileId,
            isDelete: true,
        });
    }
}

export type EvOccMenuOutput = {
    isSeparatedView: boolean;
    data?: number;
    addNew: boolean;
    isDelete?: boolean;
};
