import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnInit,
    ViewChild,
    Output,
    EventEmitter,
    NgZone,
    ElementRef,
    OnDestroy,
} from "@angular/core";
import { S25ReservationUtil } from "../../../../models/s25.reservation";
import { S25RmReservationUtil } from "../../../../models/s25.rm.reservation";
import { EventDataMicroI, S25Event } from "../../../../EventMicroI";
import { S25Util } from "../../../../../../util/s25-util";
import { EventOccUtil } from "../../s25.event.occurrence.util";
import { Range } from "../../../../../s25-slider/s25.slider.component";
import { S25EventOccurrencesService } from "../../../s25.event.occurrences.service";
import { Debounce } from "../../../../../../decorators/debounce.decorator";
import { TypeManagerDecorator } from "../../../../../../main/type.map.service";
import { S25LoadingApi } from "../../../../../s25-loading/loading.api";
import { EventMicroService } from "../../../../../../services/event.micro.service";
import { S25Reservation, S25RsReservation, S25RmReservation, rsrvType, ObjectType } from "../../../../ReservationI";
import { S25EventShareDataService } from "../../../s25.event.shared.data.service";
import { Subscription } from "rxjs";

@TypeManagerDecorator("occurrence-additional-details")
@Component({
    selector: "occurrence-additional-details",
    template: `@if (init) {
        <div>
            <section class="date-time">
                <s25-ng-editable-date
                    [val]="occ.eventStart || Date.now()"
                    [readOnly]="!isCopy || !canEdit"
                    (valChange)="onChangeDate($event)"
                ></s25-ng-editable-date>
                <s25-ng-slider
                    [model]="{
                        type: 'time',
                        ranges: sliderRange,
                        step: 0.25,
                    }"
                    (onChange)="onSliderChange($event)"
                ></s25-ng-slider>
            </section>
            <section class="locations-resources">
                @if (occ.locations?.length > 0) {
                    <div>
                        @for (arr of objectRsrvArr; track arr) {
                            <div>
                                @for (l of occ.locations[0][arr]; track l; let idx = $index) {
                                    <div>
                                        @if (occ.locations[0][arr]["length"] > 0) {
                                            <div class="location {{ arr }}">
                                                <div>
                                                    <s25-item-space
                                                        [modelBean]="{ itemId: l.itemId, itemName: l.itemName }"
                                                        [includeTypeIcon]="true"
                                                        [pendingStyleClass]="
                                                            arr === 'requested' || arr === 'draft' ? '-pending' : ''
                                                        "
                                                    ></s25-item-space>
                                                    @if (canEdit) {
                                                        <div
                                                            class="c-textButton floatRight"
                                                            (click)="onObjectDelete('location', l.itemId, arr, l)"
                                                        >
                                                            {{ arr === "requested" ? "Cancel Request" : "Remove" }}
                                                            @if (!noConflict && isConflict(l.itemId, "location")) {
                                                                <span class="ngRed"> Conflict</span>
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                                @if (l.layout.itemName) {
                                                    <div>
                                                        Layout:
                                                        @if (l.locationLayout?.length > 0 && canEdit) {
                                                            <span>
                                                                <select
                                                                    class="cn-form__control"
                                                                    id="layout{{ l.layout.itemId }}"
                                                                    (change)="onLayoutChange($event, idx, arr)"
                                                                >
                                                                    @for (
                                                                        layout of l.locationLayout;
                                                                        track layout.layout_id
                                                                    ) {
                                                                        <option
                                                                            [value]="layout.layout_id"
                                                                            [selected]="
                                                                                layout.layout_id === l.layout.itemId
                                                                            "
                                                                        >
                                                                            {{ layout.layout_name }}
                                                                        </option>
                                                                    }
                                                                </select>
                                                            </span>
                                                        }
                                                    </div>
                                                }
                                                <div class="c-margin-top--single">
                                                    <label>
                                                        Instructions:
                                                        <s25-ng-editable-textarea
                                                            [(val)]="l.instructions"
                                                            [readOnly]="!canEdit"
                                                            placeholder="Add instructions"
                                                            hasCommit="true"
                                                            hasCancelButton="true"
                                                            hasCommitButton="true"
                                                            commitButtonText="Save"
                                                            canelButtonText="Canel"
                                                            (valChange)="onTextareaChange($event, 4, l, arr, idx)"
                                                        >
                                                        </s25-ng-editable-textarea>
                                                    </label>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                }
                                <!--  end arr -->
                            </div>
                        }
                    </div>
                }
                @if (occ.resources?.length > 0) {
                    <div>
                        @for (arr of objectRsrvArr; track arr) {
                            <div>
                                @for (r of occ.resources[0][arr]; track r; let i = $index) {
                                    <div>
                                        @if (occ.resources[0][arr]["length"] > 0) {
                                            <div class="resource">
                                                <div>
                                                    <s25-item-resource
                                                        [modelBean]="{ itemId: r.itemId, itemName: r.itemName }"
                                                        [includeTypeIcon]="true"
                                                        [pendingStyleClass]="
                                                            arr === 'requested' || arr === 'draft' ? '-pending' : ''
                                                        "
                                                    ></s25-item-resource>
                                                    @if (canEdit) {
                                                        <div
                                                            class="c-textButton floatRight"
                                                            (click)="onObjectDelete('resource', r.itemId, arr, r)"
                                                        >
                                                            {{ arr === "requested" ? "Cancel Request" : "Remove" }}
                                                            @if (!noConflict && isConflict(r.itemId, "resource")) {
                                                                <span class="ngRed"> Conflict</span>
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                                <div>
                                                    Quantity:
                                                    <s25-ng-editable-number
                                                        [(val)]="r.quantity"
                                                        [readOnly]="!canEdit"
                                                        [min]="1"
                                                        [max]="r?.currentStockLevel || 65536"
                                                        hasCommit="true"
                                                        hasCancelButton="true"
                                                        hasCommitButton="true"
                                                        commitButtonText="Save"
                                                        canelButtonText="Canel"
                                                        (valChange)="onQuantityChange(r.quantity, i, r, arr)"
                                                    >
                                                    </s25-ng-editable-number>
                                                </div>
                                                <div>
                                                    <label>
                                                        Instructions:
                                                        <s25-ng-editable-textarea
                                                            [(val)]="r.instructions"
                                                            [readOnly]="!canEdit"
                                                            placeholder="Add instructions"
                                                            hasCommit="true"
                                                            hasCancelButton="true"
                                                            hasCommitButton="true"
                                                            commitButtonText="Save"
                                                            canelButtonText="Canel"
                                                            (valChange)="onTextareaChange($event, 6, r, arr, i)"
                                                        >
                                                        </s25-ng-editable-textarea>
                                                    </label>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                }
                            </div>
                        }
                    </div>
                }
            </section>
            @if (isCopy) {
                <section class="buttons">
                    <button class="aw-button aw-button--primary c-margin-right--single" (click)="save()">Save</button>
                    <button class="aw-button aw-button--outline c-margin-right--single" (click)="cancel()">
                        Cancel
                    </button>
                </section>
            }
            <s25-loading-inline model="{}" class="c-margin-top--single"></s25-loading-inline>
        </div>
    }`,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OccurrenceAdditionalDetailsComponent implements OnDestroy, OnInit {
    @Input() occ: S25Reservation;
    @Input() isCopy: boolean;
    @Input() canEdit?: boolean;
    @Input() eventId?: number;
    @Input() profileId?: number;
    @Input() selectedItems?: number[];
    @Output() onSave = new EventEmitter<{ id: number }>();
    @Output() onCancel = new EventEmitter<void>();
    @Output() refreshF = new EventEmitter<void>();

    initOcc: S25Reservation;
    init: boolean;
    objectRsrvArr = ["reserved", "draft", "requested"];
    noConflict: boolean = true;
    sliderRange: Range[];
    initSliderRange: Range[];
    findResourcesConflict: any = [];
    findLocationConflict: any = [];
    event: S25Event;
    private selectedItemsSubscription: Subscription;

    constructor(
        private cd: ChangeDetectorRef,
        private zone: NgZone,
        private occurrencesService: S25EventOccurrencesService,
        private elementRef: ElementRef,
        private shareDataService: S25EventShareDataService,
    ) {}

    sortByRsrvName(arr: S25Reservation[]) {
        arr.sort((a, b) => (a.itemName > b.itemName ? 1 : a.itemName < b.itemName ? -1 : 0));
    }

    async ngOnInit() {
        if (!this.initOcc) this.initOcc = S25Util.deepCopy(this.occ);
        if (this.occ.locations.length > 0) await S25RmReservationUtil.setLocationDetails(this.occ.locations[0]);
        this.occ.locations.forEach((item) => Object.values(item).forEach(this.sortByRsrvName));
        this.occ.resources.forEach((item) => Object.values(item).forEach(this.sortByRsrvName));
        this.setSliderRange();
        this.selectedItemsSubscription = this.shareDataService.getSelectedItems().subscribe((items) => {
            this.selectedItems = items;
        });

        this.init = true;
        this.cd.detectChanges();
    }

    async onTextareaChange(
        newVal: string,
        itemTypeId: number,
        obj?: S25RmReservation | S25Reservation,
        type?: rsrvType,
        index?: number,
    ) {
        // update object insturctions
        let updateItem;
        if (itemTypeId === 4) {
            this.occ.locations[0][type][index].instructions = newVal;
            updateItem = this.occ.locations[0][type][index];
            this.updateObject(updateItem, type, 4);
        } else {
            this.occ.resources[0][type][index].instructions = newVal;
            updateItem = this.occ.resources[0][type][index];
            this.updateObject(updateItem, type, 6);
        }
    }

    // All space and resource reservations input with no label (draft,  request  & reserved)
    async onObjectDelete(
        type: ObjectType,
        id: number,
        nodeName: string,
        removeItem: S25RmReservation | S25RsReservation,
    ) {
        let namePlural: string;
        let nameSingular: string;

        let accuUpdateItems: number[] = Object.values(S25Util.clone(this.selectedItems));
        if (!accuUpdateItems.includes(this.occ.itemId)) accuUpdateItems.push(this.occ.itemId); //  apply to selected rsrv want to apply to inline edit, if current rsrv not in the selected, added to it

        if (type === "location") {
            S25ReservationUtil.removeLocationsById(this.occ, [id]);
            namePlural = "spaces";
            nameSingular = "space";
        } else {
            S25ReservationUtil.removeResourceById(this.occ, [id]);
            namePlural = "resources";
            nameSingular = "resource";
        }

        const restult = await EventOccUtil.normalizeDeleteRsrvWSData(
            removeItem as S25RmReservation,
            null,
            nodeName,
            this.occ,
            type,
        );

        const reservations = [];
        for (const item of accuUpdateItems) {
            reservations.push({
                rsrvId: item, //rsrvId
                remove: restult.remove,
            });
        }

        let payload: EventDataMicroI = {
            items: [
                {
                    kind: "event",
                    id: this.eventId,
                    profiles: [
                        {
                            profileId: this.profileId,
                            reservations: reservations,
                        },
                    ],
                },
            ],
        };

        this.setLoading(true);
        const [ok, error] = await S25Util.Maybe(EventMicroService.putEventReservation(this.eventId, payload));
        this.setLoading(false);
        if (ok) this.refreshF.emit();
        if (error) return S25Util.showError(error, "There was an error while attempting to delete this reservation.");
    }

    async onLayoutChange(e: any, index: any, type: rsrvType) {
        this.occ.locations[0][type][index].layout.itemId = parseInt(e.target.value);
        this.occ.locations[0][type][index].layout.itemName = e.target.selectedOptions[0].text;
        let updateItem = this.occ.locations[0][type][index];
        this.updateObject(updateItem, type, 4);
    }

    onChangeDate(e: any) {
        let date = this.newDateSet(e);
        this.checkObjectsAvailability(date);
    }

    @Debounce(300) // Avoid double calls
    onSliderChange(e: any) {
        let date: any = [{ startDt: e.ranges[1].start, endDt: e.ranges[1].end }];
        this.checkObjectsAvailability(date);

        // this.initSliderRange.forEach((range: any, index: number ) => {
        //      // Update the start property of the range in initMode
        //      range.start = (new Date(range.start)).setHours( e.ranges[index].start);
        //      range.start = (new Date(range.start)).setMinutes( (e.ranges[index].start % 1) * 60);
        //      range.start = new Date(range.start);

        //      range.end = (new Date(range.end)).setHours( e.ranges[index].end);
        //      range.end = (new Date(range.end)).setMinutes( (e.ranges[index].end % 1) * 60);
        //      range.end = new Date(range.end);
        //  });
        //   console.log('tempSliderRange', this.initSliderRange);
    }

    onQuantityChange(newValalue: number, index: number, r: S25RsReservation, type: rsrvType) {
        this.occ.resources[0][type][index].quantity = newValalue;
        let updateItem = this.occ.resources[0][type][index];
        this.updateObject(updateItem, type, 6);
    }

    newDateSet(e: any) {
        const getDiffDay: number = S25Util.date.diffDays(this.occ.eventStart, e);
        const newStartDate = S25Util.date.addDays(this.occ.setupStart, getDiffDay);
        const newEndDate = S25Util.date.addDays(this.occ.takeDownEnd, getDiffDay);
        const date: any = [{ startDt: newStartDate, endDt: newEndDate }];

        this.occ.eventStart = S25Util.date.addDays(this.occ.eventStart, getDiffDay);
        this.occ.eventEnd = S25Util.date.addDays(this.occ.eventEnd, getDiffDay);
        this.occ.setupStart = S25Util.date.addDays(this.occ.setupStart, getDiffDay);
        this.occ.takeDownEnd = S25Util.date.addDays(this.occ.takeDownEnd, getDiffDay);
        if (this.occ?.preEventStart) this.occ.preEventStart = S25Util.date.addDays(this.occ.preEventStart, getDiffDay);
        if (this.occ?.postEventEnd) this.occ.postEventEnd = S25Util.date.addDays(this.occ.postEventEnd, getDiffDay);

        return date;
    }

    checkObjectsAvailability(date: []) {
        return S25ReservationUtil.checkObjectsDatesAvailability(
            this.eventId,
            this.profileId,
            date,
            this.occ.locations,
            this.occ.resources,
        ).then((data: any) => {
            data.forEach((item: any) => {
                // Check if the object has properties named "resources" and "spaces"
                if ("resources" in item) {
                    this.findResourcesConflict = item.resources.resource.filter(function (i: any) {
                        return i.has_conflicts === "T";
                    });
                }
                if ("spaces" in item) {
                    this.findLocationConflict = item.spaces.space.filter(function (i: any) {
                        return i.has_conflicts === "T";
                    });
                }
            });

            if (this.findLocationConflict.length > 0 || this.findResourcesConflict.length > 0) {
                this.noConflict = false;
                this.cd.detectChanges();
                alert("Conflict");
            } else {
                /// get payload ready then save data when the service available
                this.noConflict = true;
                this.cd.detectChanges();
                // alert("No conflict,  but data is not saving, waiting for Micro Service done");
            }
        });
    }

    async save() {
        let event = this.occurrencesService.S25Event;
        let date: any = [{ startDt: this.occ.setupStart, endDt: this.occ.takeDownEnd }];
        let normalizeSaveData = EventOccUtil.normalizeEventWSData(event, this.occ, this.isCopy, false);
        let data = normalizeSaveData[0].data;

        if (this.isCopy && this.occ.eventStart === this.initOcc.eventStart) {
            alert("Please select pick a new date.");
            return false;
        }

        this.checkObjectsAvailability(date);

        if (this.noConflict) {
            this.setLoading(true);
            const [ok, error] = await S25Util.Maybe(EventMicroService.microPutEventDetail(data, this.eventId));
            this.setLoading(false);
            if (ok) {
                if (this.isCopy) this.onSave.emit();
            }
            if (error) return S25Util.showError(error, "There was an error while attempting to copy this reservation.");
        }
    }

    cancel() {
        this.init = false;
        delete this.occ;
        this.cd.detectChanges();
        this.occ = this.initOcc;
        this.setSliderRange();
        this.init = true;
        this.cd.detectChanges();
        if (this.isCopy) this.onCancel.emit();
    }

    isConflict(id: number, type: string) {
        if (type === "location") {
            let find = this.findLocationConflict.find((f: any) => {
                return f && f.space_id === id;
            });
            return find;
        } else {
            let find = this.findResourcesConflict.find((f: any) => {
                return f && f.resource_id === id;
            });
            return find;
        }
    }

    setSliderRange() {
        this.zone.run(() => {
            this.sliderRange = [
                { start: this.occ.eventStart, end: this.occ.eventEnd, startLabel: "Start", endLabel: "End" },
            ];
            if (this.occ.preEventStart && this.occ.postEventEnd) {
                this.sliderRange.push({
                    start: this.occ.preEventStart,
                    end: this.occ.postEventEnd,
                    startLabel: "Pre Event",
                    endLabel: "Post Event",
                });
            }
            this.sliderRange.push({
                start: this.occ.setupStart,
                end: this.occ.takeDownEnd,
                startLabel: "Set Up",
                endLabel: "Take Down",
            });
            this.initSliderRange = S25Util.deepCopy(this.sliderRange);
        });
    }

    async updateObject(updateItem: any, type: rsrvType, itemTypeId: number) {
        let event = this.occurrencesService.S25Event;
        let reservations: any[] = [];
        let accuUpdateItems: number[] = Object.values(S25Util.clone(this.selectedItems));
        if (!accuUpdateItems.includes(this.occ.itemId)) accuUpdateItems.push(this.occ.itemId); //  apply to selected rsrv want to apply to inline edit, if current rsrv not in the selected, added to it

        if (itemTypeId === 4) {
            for (const item of accuUpdateItems) {
                //check if rsrv has the update object
                let find = await S25ReservationUtil.getObjectRsrvId(event, item, updateItem.itemId, itemTypeId);
                if (find)
                    reservations.push({
                        rsrvId: item, //rsrvId
                        spaces: [
                            {
                                [type]: [
                                    {
                                        spaceId: updateItem.itemId,
                                        layoutId: updateItem?.layout?.itemId,
                                        share: updateItem.isShare,
                                        instructions: updateItem.instructions,
                                        rating: updateItem.rating,
                                    },
                                ],
                            },
                        ],
                    });
            }
        } else {
            for (const item of accuUpdateItems) {
                let find = await S25ReservationUtil.getObjectRsrvId(event, item, updateItem.itemId, itemTypeId);
                if (find)
                    reservations.push({
                        rsrvId: item, //rsrvId
                        resources: [
                            {
                                [type]: [
                                    {
                                        resourceId: updateItem.itemId,
                                        quantity: updateItem.quantity,
                                        instructions: updateItem.instructions,
                                    },
                                ],
                            },
                        ],
                    });
            }
        }
        let payload: EventDataMicroI = {
            items: [
                {
                    kind: "event",
                    id: this.eventId,
                    profiles: [
                        {
                            profileId: this.profileId,
                            reservations: reservations,
                        },
                    ],
                },
            ],
        }; //end payload

        this.setLoading(true);
        const [ok, error] = await S25Util.Maybe(EventMicroService.putEventReservation(this.eventId, payload));
        this.setLoading(false);

        if (error) return S25Util.showError(error, "There was an error while attempting to update this reservation.");
    }

    setLoading(yes: boolean) {
        if (yes) {
            S25LoadingApi.init(this.elementRef.nativeElement);
        } else {
            S25LoadingApi.destroy(this.elementRef.nativeElement);
        }
        this.cd.detectChanges();
    }

    ngOnDestroy() {
        if (this.selectedItemsSubscription) {
            this.selectedItemsSubscription.unsubscribe();
        }
    }
}
